<template>
  <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">作业发放</span>
        <!-- 面包屑 -->
        <div class="crumbs">
          <a class="crumbs_item" @click="$router.go(-1)">作答记录 / </a>
          <span class="crumbs_item crumbs_last">作答详情</span>
        </div>
      </div>
    </div>

    <!-- 详情区 -->
    <div class="content-box">
      <div class="cow-top">
        <p class="h4">关联课程：{{detail.courseName}}。</p>
        <div class="font">
          <span>作业名称：{{detail.homeworkTitle}}</span>
          <a @click="isHomworkShow = true">作业详情></a>
        </div>
      </div>

      <!-- 学员信息 -->
      <div class="student-info border">
        <div class="line">
          <p><span>学员信息：</span>{{detail.userName ? detail.userName : '-'}}</p>
          <p><span>提交时间：</span>{{detail.submitTime ? detail.submitTime : '-'}}</p>
          <p v-if="$route.query.type == 1">
            <span>评分：</span>{{detail.score ? detail.score : '-'}}
          </p>
          <p v-if="$route.query.type == 2">
            <span>评分：</span>
            <template v-if="detail.historyStatus == 3">{{detail.score}}</template>
            <template v-else>-</template>
          </p>
        </div>
        <div class="line">
          <p v-if="$route.query.type == 1">
            <span>{{detail.status == 5 ? '驳回原因：' : '评语：'}}</span>{{detail.reply ? detail.reply : '-'}}
          </p>
          <p v-if="$route.query.type == 2">
            <span>{{detail.historyStatus == 2 ? '驳回原因：' : '评语：'}}</span>{{detail.historyReply ? detail.historyReply : '-'}}
          </p>
        </div>
      </div>

      <div class="student-work border">
        <h5>{{detail.status ? '学员作业' : '作业详情'}}</h5>
        
        <!-- status 0 未提交作业 -->
        <div v-if="detail.status">
          <div class="col-item" v-if="$route.query.type == 1 ? (attachlist.length) : (historyAttachlist.length)">
            <span class="title">作业附件：</span>
            <div class="grey">
              <p>提示：点击文件即在线预览</p>
              <span @click="openFile(item.url)" title="点击在线查看" v-for="(item,index) in ($route.query.type == 1 ? (attachlist) : (historyAttachlist))" :key="index">{{item.name}}</span>
            </div>
          </div>
          <div class="col-item" v-if="$route.query.type == 1 ? detail.answerContent : detail.historyContent">
            <span class="title">作业文本：</span>
            <a-textarea class="textarea" :rows="4" :value="($route.query.type == 1 ? (detail.answerContent) : (detail.historyContent))" :disabled="true" auto-size />
          </div>
          <div class="col-item" v-if="detail.supplyAttach.length">
            <span class="title">补充资料：</span>
            <div class="grey">
              <p>提示：点击文件即在线预览</p>
              <span @click="openFile(item.url)" title="点击在线查看" v-for="(item,index) in detail.supplyAttach" :key="index">{{item.name}}</span>
            </div>
          </div>
        </div>
        <div class="error" v-else>
          学生未提交作业
        </div>
      </div>
    </div>

    <div class="foot-btn">
      <a-button @click="$router.go(-1)">返回</a-button>
    </div>

    <!-- 作业详情 -->
    <a-modal v-model="isHomworkShow" align="center" title="作业详情" >
      <div class="homework-detail">
        <h3>{{detail.homeworkTitle}}</h3>
        <div v-html="detail.libraryContent"></div>
        <div class="file-list" v-if="attachment.length">
          <div class="file-item" title="点击在线查看" @click="openFile(item.url)" v-for="(item,index) in attachment" :key="index">
            <img class="icon" :src="getSuffix(item.name)" alt="">
            <span>{{item.name}}</span>
          </div>
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="isHomworkShow = false">返回</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { openFile, viewHeic } from '@/unit/fun.js'
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      docIcon: require('./icon/icon-doc.png'),
      imgIcon: require('./icon/icon-img.png'),
      txtIcon: require('./icon/icon-txt.png'),
      pdfIcon: require('./icon/icon-pdf.png'),
      mp4Icon: require('./icon/icon-mp4.png'),
      xlsIcon: require('./icon/icon-xls.png'),
      isHomworkShow:false,
      detail:{},
      attachment:[], // 配置作业附件
      attachlist:[],  // 学生作业附件
      historyAttachlist: [],  // 学生历史作业附件
      heicLoading: false,
    }
  },
  // 事件处理器
  methods: {
    // 查询详情数据
    getDetail(){
      let url = ''
      let params = {}
      if(this.$route.query.type == 1){
        url = '/hxclass-management/homeworkAnswer/userAnswer/details'
        params = {
          answerId:  this.$route.query.answerId,
        }
      } else if (this.$route.query.type == 2){
        url = '/hxclass-management/homeworkAnswer/select/byIdAndAnswerId'
        params = {
          answerId:this.$route.query.answerId,
          id:  this.$route.query.id	,
        }
      }

      this.$ajax({
        method: 'get',
        url: url,
        params: params
      }).then(res => {
        if (res.code == 200 && res.success) {
          // 学生补充资料格式转换
          if(res.data.supplyAttach){
            res.data.supplyAttach = JSON.parse(res.data.supplyAttach)
          } else {
            res.data.supplyAttach = []
          }
          
          this.detail = res.data

          // 配置作业附件格式转换
          if(res.data.attachment){
            this.attachment = JSON.parse(res.data.attachment)
          } else {
            this.attachment = []
          }

          // 学生作业附件格式转换
          if(res.data.attachlist){
            this.attachlist = JSON.parse(res.data.attachlist)
          } else {
            this.attachlist = []
          }

          // 学生历史作业附件格式转换
          if(res.data.historyAttachlist){
            this.historyAttachlist = JSON.parse(res.data.historyAttachlist)
          } else {
            this.historyAttachlist = []
          }
        }
      })
    },



    // 解析文件后缀返回对应图片
    getSuffix(string){
      if(string){
        let lastIndex = string.lastIndexOf('.');
        let suffix = string.substring(lastIndex+1)
        let imgSuffix = ['jpg','png','gif','jpeg']
        let xlsSuffix = ['xls','xlsx']
        let imgIcon = ''
        if(suffix == 'doc' || suffix == 'docx'){
          imgIcon = this.docIcon
        } else if (suffix == 'txt'){
          imgIcon = this.txtIcon
        } else if (suffix == 'pdf'){
          imgIcon = this.pdfIcon
        } else if (suffix == 'mp4'){
          imgIcon = this.mp4Icon
        } else if (imgSuffix.indexOf(suffix) != -1){
          imgIcon = this.imgIcon
        } else if (xlsSuffix.indexOf(suffix) != -1){
          imgIcon = this.xlsIcon
        }

        return imgIcon
      } else {
        return false;
      }
    },
    // 打开文件
    openFile(url){
      const isHEIC = url.endsWith('.heic');

      if (isHEIC) {
        if(this.heicLoading) return
        this.heicLoading = true
        this.$message.loading({ content: "HEIC图片在线预览解析中...", duration: 0 });
        viewHeic(url).then((res)=>{
          window.open(res);
          this.$message.success();
          this.heicLoading = false
        })
      } else {
        openFile(url)
      }
    },
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { 
    this.getDetail()
    // if(this.$route.query.type == 1){
    // } else if (this.$route.query.type == 2){
    //   this.getDetails()
    // }

  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.cow-top{
  margin-top: 20px;
  background: #f9f9f9;
  min-height: 100px;
  padding: 20px;
  border-radius: 10px;
  color: #333333;
  .h4{
    font-size: 18px;
    font-weight: bold;
    color: #666666;
  }
  .font{
    display: flex;
    margin-top: 20px;
    margin-right: 50px;
    span{
      margin-right: 50px;
    }
  }
}

.border{
  margin-top: 40px;
  border: 1px solid #999;
  padding: 20px;
  border-radius: 5px;
}

.student-info{
  margin-top: 30px;
  .line{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    span{
      font-weight: bold;
    }
  }
  .line:last-child{
    margin-bottom: 0;
  }
}

.student-work{
  h5{
    font-weight: bold;
  }
  .col-item{
    margin-top: 30px;
    .title{
      display: inline-block;
      margin-bottom: 7px;
    }
    .grey{
      margin-top: 20px;
      background: #f9f9f9;
      min-height: 100px;
      padding: 22px;
      border-radius: 3px;
      span{
        display: inline-block;
        font-size: 14px;
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 10px 20px;
        background: #ffffff;
        border-radius: 4px;
        cursor: pointer;
      }
      p{
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
  }

  .error{
    text-align: center;
    margin: 50px auto;
  }
}

.homework-detail{
  text-align: left;
  h3{
    font-size: 16px;
    font-weight: bold;
    color: #666666;
    margin-bottom: 5px;
  }  

  /deep/strong{
    font-weight: bold;
  }
  /deep/em{
    font-style: italic;
  }
  /deep/img{
    max-width: 100%;
  }
  /deep/.remarks{
    max-width: 100%;
    height: auto !important;
    -webkit-line-clamp: 10000 !important;
  }
  /deep/ video{
    width: 100%;
  }

  .file-list{
    .file-item{
      border: 1px solid #999;
      margin-top: 10px;
      padding: 10px;
      cursor: pointer;
      .icon{
        width: 45px;
      }
    }
  }
}

.foot-btn{
  margin-top: 20px;
}
.textarea{
  min-height: 100px;
}
</style>
